export const AVA_DEFAULT_PLAYER = "@ava-default-player5";
export const AVA_DEFAULT_PLAYER_VALUE = "@ava-default-player-value5";

export const alterarDefaultPlayer = () => {

    let defaultPlayer = localStorage.getItem(AVA_DEFAULT_PLAYER);
    if(!defaultPlayer) {
        localStorage.setItem(AVA_DEFAULT_PLAYER, '2');
    }
    if(defaultPlayer === '2') {
        localStorage.setItem(AVA_DEFAULT_PLAYER, '1');
    } else {
        localStorage.setItem(AVA_DEFAULT_PLAYER, '2');
    }
}

export const getDefaultPlayer = () => {
    let defaultPlayer = localStorage.getItem(AVA_DEFAULT_PLAYER);
    if(!defaultPlayer) {
        return '1';
    }
    return defaultPlayer;
}

export const getDefaultPlayerValue = () => {
    let defaultPlayer = localStorage.getItem(AVA_DEFAULT_PLAYER_VALUE);
    if(!defaultPlayer) {
        return '0';
    }
    return defaultPlayer;
}

export const alterarDefaultPlayerValue = (value) => {
    let defaultPlayerValue = localStorage.getItem(AVA_DEFAULT_PLAYER_VALUE);
    if(defaultPlayerValue !== value) {
        localStorage.setItem(AVA_DEFAULT_PLAYER, '1');
        localStorage.setItem(AVA_DEFAULT_PLAYER_VALUE, value);
        return true;
    }
    return false;
}
