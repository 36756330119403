import React from 'react';
import ReactDOM from 'react-dom';
import Routes from "./routes";
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import store from "./store";

import TagManager from 'react-gtm-module'
import {QueryClientProvider} from "react-query";
import {queryClient} from "./services/queryClient";

const tagManagerArgs = {
    gtmId: process.env.REACT_APP_TAGMANAGER
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <Routes/>
            </QueryClientProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

reportWebVitals();
