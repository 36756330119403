import { createStore } from "redux";
import {getDefaultPlayer, getDefaultPlayerValue} from "../services/player";

const INITIAL_STATE = {
    modulos: null,
    conteudo: null,
    video: null,
    parte: null,
    reloadModulo: false,
    reloadAula: false,
    reloaConteudo: false,
    reloadModuloNext: false,
    reloadAulaNext: false,
    reloaConteudoNext: false,
    prev: null,
    next: null,
    prevParte: null,
    nextParte: null,
    conteudoAtual: null,
    parteAtual: null,
    tipoPlayer: getDefaultPlayer()
};

// eslint-disable-next-line no-unused-vars
function setConteudoStore(state = INITIAL_STATE, action) {
    if (action.type === "SET_CONTEUDO") {
        return {
            ...state,
            conteudo: action.payload.conteudo,
            video: action.payload.video,
            parte: action.payload.parte,
            prev: action.payload.prev,
            next: action.payload.next,
            prevParte: action.payload.prevParte,
            nextParte: action.payload.nextParte,
            tipoPlayer: getDefaultPlayer()
        };
    } else if (action.type === "SET_ONLY_CONTEUDO") {
        return {
            ...state,
            conteudo: action.payload.conteudo,
        };
    } else if (action.type === "SET_RELOAD_MODULO") {
        return {
            ...state,
            reloadModulo: action.payload.reloadModulo,
        };

    } else if (action.type === "SET_RELOAD_AULA") {
        return {
            ...state,
            reloadAula: action.payload.reloadAula,
        };

    } else if (action.type === "SET_RELOAD_CONTEUDO") {
        return {
            ...state,
            reloadConteudo: action.payload.reloadConteudo,
        };
    } else if (action.type === "SET_RELOAD_MODULO_NEXT") {
        return {
            ...state,
            reloadModuloNext: action.payload.reloadModuloNext,
        };

    } else if (action.type === "SET_RELOAD_AULA_NEXT") {
        return {
            ...state,
            reloadAulaNext: action.payload.reloadAulaNext,
        };

    } else if (action.type === "SET_RELOAD_CONTEUDO_NEXT") {
        return {
            ...state,
            reloadConteudoNext: action.payload.reloadConteudoNext,
        };
    }
    else if (action.type === "SET_RELOAD_PLAYLIST") {
        return {
            ...state,
            reloadPlaylist: action.payload.reloadPlaylist,
            conteudoDeleted: action.payload.conteudoDeleted,
            playlistEdit: action.payload.playlistEdit,
            playlistDeleted: action.payload.playlistDeleted
        };
    }
    else if (action.type === "SET_ATUAL") {
        return {
            ...state,
            conteudoAtual: action.payload.conteudoAtual,
            parteAtual: action.payload.parteAtual,
        };

    } else if (action.type === "SET_MODULOS") {
        return {
            ...state,
            modulos: action.payload.modulos,
        };

    } else if (action.type === "SET_TIPO_PLAYER") {
        return {
            ...state,
            tipoPlayer: action.payload.tipoPlayer,
        };

    } else {
        return state;
    }
}

const store = createStore(setConteudoStore);

export default store;
