import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import '../src/styles/style.scss';
import "./assets/fonts/roboto/index.css";

const Home = React.lazy(() => import("./pages/Home"));


const loading = () => <div className={"empty"}></div>;

export default function Routes() {
  return (
    <BrowserRouter>
      <React.Suspense fallback={loading()}>
        <Switch>
          <Route path="/" exact={true} component={Home} />
          <Route path="/home" exact={true} component={Home} />
          <Route path="/player-ava" exact={true} component={Home} />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
}
